import React, { Component } from "react"
import Layout from "../components/layout"
import PageHeader from "../components/pageHeader"
import styled from "styled-components"
import PhotographyItem from "../components/photography-item"
import Lightbox from "react-images"
import FsLightbox from "fslightbox-react"
import SEO from "../components/seo"
import { graphql } from "gatsby"

const Grid = styled.div`
  margin-top: 40vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
`

const TagsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 30vh;
  overflow-x: scroll;
`
const Tag = styled.button`
  margin: 0 10px;
  font-size: 11px;
  background: none;
  border: none;
  color: white;
  font-family: "Open Sans";
  cursor: pointer;

  :focus {
    outline: none;
  }
`
const Container = styled.div`
  flex-basis: 16.6%;
  height: 200px;
  text-align: center;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  /*Media Queries*/
  @media (max-width: 1000px) {
    flex-basis: 25%;
  }

  @media (max-width: 850px) {
    flex-basis: 33.33%;
  }
  @media (max-width: 600px) {
    flex-basis: 50%;
  }
`
const Cover = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.3s ease-out;
  transform: scale(1);

  ${Container}:hover & {
    transform: scale(1.1);
  }
`
const TextContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  padding: 0 5px;
  transform: translate(-50%, -50%);
`
const Title = styled.h3`
  font-family: "alternategothic2_btregular";
  margin-bottom: 5px;
  color: white;
  text-transform: uppercase;
`

class MoreVibesPage extends Component {
  state = {
    loadingGallery: true,
    activeGallery: null,
    isOpen: true,
    photoIndex: 0,
    imageUrls: null,
  }

  componentDidMount() {
    //Set the gallery to be the first title returned
    const morevibesData = this.props.data.allWordpressPost.edges
    this.setState({
      videoUrls: morevibesData.map(object => object.node.acf.video_url),
      photoIndex: 0,
      isOpen: false,
    })
  }

  openLightbox = i => {
    this.setState({ isOpen: !this.state.isOpen, photoIndex: i })
  }

  render() {
    const morevibesData = this.props.data.allWordpressPost.edges
    const { isOpen, activeGallery, photoIndex, videoUrls } = this.state

    return (
      <Layout>
        <SEO title="More Vibes" />
        <PageHeader>More Vibes</PageHeader>
        <Grid>
          {morevibesData.map((vibe, index) => (
            <>
              <Container key={index} onClick={() => this.openLightbox(index)}>
                <Cover
                  src={`https://img.youtube.com/vi/${vibe.node.acf.video_url.substr(
                    vibe.node.acf.video_url.length - 11
                  )}/0.jpg`}
                />
                <TextContainer>
                  <Title>{vibe.node.title}</Title>
                </TextContainer>
              </Container>
            </>
          ))}
        </Grid>
        {videoUrls && (
          <FsLightbox
            key={videoUrls}
            toggler={isOpen}
            slide={photoIndex + 1}
            sources={videoUrls}
            type="youtube"
          />
        )}
      </Layout>
    )
  }
}

export default MoreVibesPage

export const morevibesQuery = graphql`
  query($category: String = "More Vibes") {
    allWordpressPost(
      filter: { categories: { elemMatch: { name: { eq: $category } } } }
    ) {
      edges {
        node {
          title
          acf {
            video_url
          }
        }
      }
    }
  }
`
