import React, { Component } from "react"
import styled from "styled-components"

const Container = styled.div`
  flex-basis: 16.6%;
  height: 200px;
  text-align: center;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  /*Media Queries*/
  @media (max-width: 1000px) {
    flex-basis: 25%;
  }

  @media (max-width: 850px) {
    flex-basis: 33.33%;
  }
  @media (max-width: 600px) {
    flex-basis: 50%;
  }
`
const Cover = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.3s ease-out;
  transform: scale(1);

  ${Container}:hover & {
    transform: scale(1.1);
  }
`

class PhotographyItem extends Component {
  state = {}
  render() {
    return (
      <Container>
        <Cover src={this.props.cover} />
      </Container>
    )
  }
}

export default PhotographyItem
